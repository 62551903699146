<template>
  <div>
    <div class="air__utils__heading">
      <h5>Generación de Servicios</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--  Información-->
    <div class="row">
      <div class="col">
        <a-alert type="error" class="text-center small" message="Los folios con formato G_XXXXXXXXXX son temporales y sólo aplican para aquellos eventos que no tienen No. de Programación de SAP asignado."/>
      </div>
      <div class="col">
        <div class="row justify-content-center">
          <div class="col"><h5 class="text-uppercase text-secondary align-middle"><strong>Estatus:</strong></h5></div>
          <div class="col mr-2"><a-badge status="success" text="Concluido"/></div>
          <div class="col mx-2"><a-badge status="warning" text="En Plataforma"/></div>
          <div class="col mx-2"><a-badge status="error" text="Pendiente"/></div>
        </div>
      </div>
    </div>
    <!--  Información-->
    <!--  Filtros-->
<!--    <b-button @click="getCalendarData">Busca</b-button>-->
    <div class="row justify-content-end align-self-center my-4">
      <div class="col-sm-12 mb-sm-2 col-md-4 mb-md-0">
        <b-form-group id="current-group" label="Corriente" label-for="current">
          <b-form-select v-model="currentFilterValue" :options="currentsCatalog" id="current"
                         value-field="code" text-field="name" label="Corrientes" @change="searchData">
            <template slot="first">
              <b-form-select-option value="" >Todas las Corrientes</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 mb-sm-2 col-md-4 mb-md-0">
        <b-form-group id="service-group" label="Servicio" label-for="service">
          <b-form-select v-model="serviceFilterValue" :options="serviceTypesCatalog" id="service"
                         value-field="name" text-field="name" label="Tipo de Servicio" @change="searchData">
            <template slot="first">
              <b-form-select-option value="" >Todos los Servicios</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-12 mb-sm-2 col-md-4 mb-md-0">
        <globalPlantFilter @onChangeValue="searchData"  @filterValue="plantFilterValue = $event"/>
      </div>
    </div>
    <div class="row justify-content-end align-self-center my-4">
      <div class="col-sm-4 mt-sm-2 col-md-6 col-lg-3 mt-lg-1">
        <b-form-group id="status-group" label="Estatus" label-for="status">
          <b-form-select v-model="statusFilterValue" :options="servicePlanningStatus" id="status" label="Estatus" @change="searchData">
            <template slot="first">
              <b-form-select-option value="" >Todos los Estatus</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-sm-4 mt-sm-2 col-md-6 col-lg-3 mt-lg-1">
        <b-form-group id="year-group" label="Año" label-for="year">
          <b-form-select v-model="yearFilterValue" :options="yearsCatalog" id="year" label="Año" @change="searchData"/>
        </b-form-group>
      </div>
      <div class="col-sm-4 mt-sm-2 col-md-6 col-lg-3 mt-lg-1">
        <b-form-group id="month-group" label="Mes" label-for="month">
          <b-form-select v-model="monthFilterValue" id="month" :options="monthsCatalog" label="Mes" @change="searchData"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 offset-sm-4 mt-sm-2 col-md-6 offset-md-0 mt-md-4 col-lg-3 mt-lg-4">
        <div class="row">
<!--          <div class="col-6">-->
<!--            <responsive-button block variant="primary" pill :text="viewControl ? 'Tabla' : 'Calendario'" size="sm" responsive="xl" :icon="viewControl ? 'list-ul' : 'calendar3'" @ClickEvent="ToggleView"/>-->
<!--          </div>-->
          <div class="col-12">
            <responsive-button block variant="secondary" pill text='Nuevo' size="sm" responsive="xl" icon="plus-circle-fill" @ClickEvent="OpenNewRegister"></responsive-button>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <!--  Filtros-->
    <!--Calendario-->
    <my-spinner :load="calendarSpinner"/>
    <template v-show="!calendarSpinner">
      <FullCalendar v-show="viewControl" :options="calendarOptions"  ref="fullCalendar">
        <template #eventContent="arg">
          <div :id="arg.event.title">
            <!--          <b-icon icon="camera-fill" class="text-real-blue mx-2"/>-->
            <!--          // TODO: Definir color para el resto de los status-->
            <b-badge variant="primary" v-if="arg.event.extendedProps.service_planning_status === 'Concluido' ">{{ arg.event.title }}</b-badge>
            <b-badge variant="warning" v-if="arg.event.extendedProps.service_planning_status === 'En Plataforma' ">{{ arg.event.title }}</b-badge>
            <b-badge variant="danger" v-if="arg.event.extendedProps.service_planning_status === 'Pendiente' ">{{ arg.event.title }}</b-badge>
            <span v-else>{{ arg.event.title }}</span>
<!--            <br>-->
<!--            <generatorLogicInfo :service="arg.event.extendedProps"/>-->
          </div>
          <b-popover :target="arg.event.title" triggers="hover" placement="top">
            <template #title>{{ arg.event.title }}</template>
            Programado: {{moment(arg.event.extendedProps.servicePlanningsPeriods.program_dated_at).format('DD/MM/YYYY')}}
            <br>
            <generatorLogicInfo :service="arg.event.extendedProps"/>
          </b-popover>
        </template>
      </FullCalendar>
    </template>
    <!--  Calendario-->
    <!--  Tabla-->
    <services-table v-show="!viewControl"
      :tableObject="tableObject"
      @Filter="getTableData"
      @searchInput="searchInput = $event"/>
    <!--  Tabla-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import 'moment/locale/es'
import { setKeys } from '@/helpers/calendarHelper'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import servicesTable from '../components/servicesTable'
import generatorLogicInfo from '@/views/geocycle/service_plannings/components/generatorLogicInfo'
import { catalogMixin } from '@/mixins/catalogMixin'
export default {
  name: 'calendarTable',
  components: {
    servicesTable,
    FullCalendar, // make the <FullCalendar> tag available
    generatorLogicInfo,
  },
  mixins: [catalogMixin],
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Calendario',
          aIcon: '',
        },
      ],
      resource: 'services',
      viewControl: true, // true-> calendar/false->table
      // services,
      data: [],
      today: moment(),
      yearFilterValue: moment().year(),
      monthFilterValue: moment().month() + 1,
      currentFilterValue: '',
      serviceFilterValue: '',
      statusFilterValue: '',
      dateFilter: {},
      plantFilterValue: '',
      // FullCalendar
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        footerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        locale: 'es-ES',
        locales: allLocales,
        initialView: 'timeGridWeek',
        // events: services,
        events: [],
        nowIndicator: true,
        editable: false,
        selectable: true,
        unselectAuto: true,
        selectOverlap: true, // Determines wether the user is allowed to select periods of time that are occupied by events
        selectMirror: false,
        dayMaxEvents: this.dayMaxEventsValue,
        weekends: true,
        eventBackgroundColor: '#ffffff',
        eventBorderColor: '#006633',
        eventTextColor: '#006633',
        eventDisplay: 'block', // list-item
        eventClick: this.handleEventClick, // Al dar click en el servicio
        eventsSet: this.handleEvents, // Al dar click en flechas/Hoy Mes-Semana-Dia
        select: this.handleDateSelect, // al seleccionar un periodo
        // dateClick: this.dateClick, // al dar click en el calendario regresa la fecha
        /* others events (can update a remote database) when these fire:
          eventAdd: Called after an event has been added to the calendar
          eventChange: Called after an event has been modified in some way
          eventRemove: Called after an event has been removed from the calendar
        */
        /* mouse events:
          eventMouseEnter: Triggered when the user mouses over an event
          eventMouseLeave: Triggered when the user mouses out an event
        */
        /* Dragging & Resizing events
          eventDragStart: Triggered when event dragging begins.
          eventDragStop: Triggered when event dragging stops.
          eventDrop: Triggered when dragging stops and the event has moved to a different day/time.
          drop: Called when an external draggable element or an event from another calendar has been dropped onto the calendar.
          eventReceive: Called when an external draggable element with associated event data was dropped onto the calendar.Or an event from another calendar.
          eventLeave: Triggered when on a calendar when one if its events is about to be dropped onto another calendar.
          eventResizeStart: Triggered when event resizing begins.
          eventResizeStop: Triggered when event resizing stops.
          eventResize: Triggered when resizing stops and the event has changed in duration.
        */
      },
      calendarSpinner: false,
      currentEvents: [],
      // FullCalendar
      // Table
      searchInput: null,
      tableObject: {
        data: [],
        header: 'Tabla de Servicios',
        columns: [
          {
            title: 'No. Programación SAP',
            dataIndex: 'sap_programming_code',
            key: 'sap_programming_code',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_programming_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Planta',
            dataIndex: '',
            key: 'work_centers',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'workCenter',
            },
          },
          {
            title: 'Transporte',
            dataIndex: 'servicePlanningsCarriers',
            key: 'transport',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'transport',
            },
          },
          {
            title: 'Corrientes',
            dataIndex: 'servicePlanningsMaterials',
            key: 'currents',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'currents',
            },
          },
          {
            title: 'Fecha y Hora de Programación',
            dataIndex: 'servicePlanningsPeriods',
            key: 'program_date',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'program_date',
            },
          },
          {
            title: 'Tipo de Servicio',
            dataIndex: 'serviceTypes',
            key: 'service',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'service',
            },
          },
          {
            title: 'Etapa del Servicio',
            dataIndex: 'lastSubstage',
            key: 'stage',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'stage',
            },
          },
          {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'status',
            },
          },
          {
            title: 'Estatus de SAP',
            dataIndex: 'sap_status',
            key: 'sap_status',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'sap_status',
            },
          },
          {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            // fixed: 'right',
            class: 'small',
            align: 'center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
        currentPageValue: 1,
      },
      // Table
      // Variables de Modal
      serviceDetailModalShow: false,
      actualService: {},
      // Variables de Modal
    }
  },
  computed: {
    ...mapState(['servicePlannings']),
    dayMaxEventsValue() {
      if (screen.width >= 992) {
        return 4
      } else {
        return true
      }
    },
  },
  methods: {
    getMixinCatalogs() {
      this.getCurrentsCatalog()
      this.getServiceTypesCatalog()
      this.getWorkCenterCatalog()
    },
    // Method of NewButton
    OpenNewRegister() {
      this.$router.push({ name: 'service', params: { folio: 0, id: '0' } })
    },
    // FullCalendar
    handleEventClick(clickInfo) {
      const folio = clickInfo.event._def.extendedProps.sap_programming_code
      const id = clickInfo.event._def.extendedProps.jsonApi.id
      this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: folio })
      this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: id })
      this.$router.push({ name: 'service', params: { folio: folio, id: id } })
    },
    handleEvents(events) {
      // Es posible acceder al tipo de vista de la siguiente forma
      // const tipoVista = this.$refs.fullCalendar.getApi().currentData.currentViewType
      // tipoVista :[dayGridMonth,timeGridWeek,timeGridDay]
      // Se accede a la fecha actual del calendario
      const calendarCurrentDate = this.$refs.fullCalendar.getApi().getDate()
      const dateYear = moment(calendarCurrentDate).year()
      const dateMonth = moment(calendarCurrentDate).month() + 1
      // Si las fechas de filtro y calendario no coinciden, se actualizan las fechas del filtro y se consultan datos
      if (dateYear !== this.yearFilterValue || dateMonth !== this.monthFilterValue) {
        this.yearFilterValue = dateYear
        this.monthFilterValue = dateMonth
        this.getCalendarData()
      }
      this.currentEvents = events
    },
    handleDateSelect(selectInfo) {
      const { startStr, endStr } = selectInfo
      this.$store.dispatch('servicePlannings/SET_CALENDAR_SELECTED_PERIOD', {
        start: moment(startStr).format('YYYY-MM-DD HH:mm'),
        end: moment(endStr).format('YYYY-MM-DD HH:mm'),
      })
      this.$router.push({ name: 'service', params: { folio: 0, id: '0' } })
    },
    // dateClick(dateClickInfo) {
    //   console.log('dateClickInfo', dateClickInfo)
    // },
    // FullCalendar
    // <!-- Get  -->
    // <!-- Order Data by date to show in calendar  -->
    searchData() {
      const calendar = this.$refs.fullCalendar.getApi()
      const calendarCurrentDate = this.$refs.fullCalendar.getApi().getDate()
      const calendarCurrentDay = moment(calendarCurrentDate).date()
      const auxMonth = (this.monthFilterValue < 10) ? '0' + this.monthFilterValue : this.monthFilterValue
      const newFilterDate = this.yearFilterValue + '-' + auxMonth + '-' + calendarCurrentDay
      // // Set Calendar Date
      calendar.gotoDate(moment(newFilterDate).format())
      if (this.viewControl) {
        // calendar request
        this.getCalendarData()
      } else {
        // table request
        this.getTableData()
      }
    },
    getCalendarData() {
      const filterParams = {
        include: 'servicePlanningsPeriods,service_planeable',
        'filter[yearCalendar]': this.yearFilterValue,
        'filter[monthCalendar]': this.monthFilterValue,
      }
      if (this.currentFilterValue !== '') {
        filterParams['filter[currentCalendar]'] = this.currentFilterValue
      }
      if (this.serviceFilterValue !== '') {
        filterParams['filter[serviceTypeCalendar]'] = this.serviceFilterValue
      }
      if (this.statusFilterValue !== '') {
        filterParams['filter[servicePlanningStatus]'] = this.statusFilterValue
      }
      if (this.plantFilterValue !== '') {
        filterParams['filter[workCenters]'] = this.plantFilterValue
      }
      this.calendarSpinner = true
      this.$store.dispatch('jv/get', ['servicePlannings', {
        params: filterParams,
      }])
        .then(response => {
          this.calendarSpinner = false
          const tableData = response
          this.data = objectArray(tableData)
          setKeys(this.data, 'sap_programming_code', 'coming_estimated_at', 'coming_estimated_hour')
          this.calendarOptions.events = this.data
        })
        .catch(() => {
          this.calendarSpinner = false
        })
    },
    // <!-- Order Data by date to show in calendar  -->
    // <!-- Order Data by date to show in table  -->
    getTableData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      const filterParams = {
        'page[number]': `${this.tableObject.pagination.current}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        sort: 'sap_programming_code',
        'filter[yearCalendar]': this.yearFilterValue,
        'filter[monthCalendar]': this.monthFilterValue,
        include: 'workCenters,servicePlanningsMaterials.currents,serviceTypes,servicePlanningsPeriods,shifts,service_planeable,spcomercialCarriers.logisticsShipmentTypes',
      }
      if (this.currentFilterValue !== '') {
        filterParams['filter[currentCalendar]'] = this.currentFilterValue
      }
      if (this.serviceFilterValue !== '') {
        filterParams['filter[serviceTypeCalendar]'] = this.serviceFilterValue
      }
      if (this.statusFilterValue !== '') {
        filterParams['filter[servicePlanningStatus]'] = this.statusFilterValue
      }

      this.tableObject.loading = true
      this.$store.dispatch('jv/get', ['servicePlannings', {
        params: filterParams,
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
        .catch(error => {
          console.log(error)
          this.tableObject.loading = false
        })
    },
    // <!-- Order Data by date to show in table  -->
    // <!-- Get  -->
    // Table
    ToggleView() {
      this.viewControl = !this.viewControl
      if (this.viewControl) {
        this.getCalendarData()
      } else {
        this.getTableData()
      }
      // Reset Filters
    },
    // Table
  },
  mounted() {
    this.getMixinCatalogs()
    this.getCalendarData()
    this.$store.dispatch('servicePlannings/RESET_DATA')
  },
}
</script>

<style scoped>
</style>
