import moment from 'moment'
import 'moment/locale/es'
// -----  HELPER TO TRANSFORM an Array of Objects with a date to an object with the dates as keys (for a-calendar)-----//

/* From this [
  { folio: 'ABC', date_hour: '2019-12-24T02:55:01+00:00',},
  { folio: 'DEF', date_hour: '2020-09-12T02:55:01+00:00',}
]
To this {
  2019124:[{ folio: 'ABC', date_hour: '2019-12-24T02:55:01+00:00',},],
  20190912:[{ folio: 'DEF', date_hour: '2020-09-12T02:55:01+00:00',},]
  }
*/

export const orderDates = (arrayDates = [], nameOfDate = 'date', nameOfTime = 'hour') => {
  if (arrayDates.length <= 0) {
    return { }
  }
  const objectDates = {}
  arrayDates.forEach((element) => {
    const formattedDate = moment(element[nameOfDate]).format('YYYYMMDD')
    if (Object.prototype.hasOwnProperty.call(objectDates, formattedDate)) {
      // La llave de la fecha ya existe
      objectDates[formattedDate].push(element)
    } else {
      objectDates[formattedDate] = []
      objectDates[formattedDate].push(element)
    }
  })
  return objectDates
}
// -----  HELPER TO Set the necessary keys (title,date) in an array of objects (for  FullCalendar)-----//
export const setKeys = (arrayDates = [], nameOfTitle, nameOfDate = 'date', nameOfTime = 'hour') => {
  if (arrayDates.length <= 0) {
    return []
  }
  return arrayDates.map(element => {
    const date = element.servicePlanningsPeriods[nameOfDate] + ' ' + element.servicePlanningsPeriods[nameOfTime]
    element.title = element[nameOfTitle]
    element.start = moment(date).format('YYYY-MM-DD HH:mm')
  })
}

// -----  HELPER TO TRANSFORM the object named value from the calendar component to a string formatted date -----//
export const calendarDdmmyyyy = (date = '') => {
  return date.clone().locale('es').format('DD/MM/YYYY')
}
export const calendarMonth = (date = '') => {
  return date.clone().locale('es').format('MM')
}
// -----  HELPER TO TRANSFORM  string date to a string formatted date using moment -----//

export const ddmmyyyy = (date = '') => {
  return moment(date).format('DD/MM/YYYY')
}
